import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "about" */ '../views/SsoLogin.vue')
  },
  {
    path: '/login2',
    name: 'login2',
    component: () => import( /* webpackChunkName: "about" */ '../views/LoginDemo.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import( /* webpackChunkName: "about" */ '../views/NoData.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/HomeIndex.vue'),
    redirect: '/EvaluationManagement',
    children: [{
        path: '/EvaluationManagement',
        name: 'EvaluationManagement',
        component: () => import( /* webpackChunkName: "about" */ '../views/EvaluationManagement.vue'),
      },
      {
        path: '/StaffManagement',
        name: 'StaffManagement',
        component: () => import( /* webpackChunkName: "about" */ '../views/StaffManagement.vue')
      },
      {
        path: '/TRPManagement',
        name: 'TRPManagement',
        component: () => import( /* webpackChunkName: "about" */ '../views/TRPManagement.vue')
      },
      {
        path: '/PostManagement',
        name: 'PostManagement',
        component: () => import( /* webpackChunkName: "about" */ '../views/PostManagement.vue')
      },
      {
        path: '/RightsManagement',
        name: 'RightsManagement',
        component: () => import( /* webpackChunkName: "about" */ '../views/RightsManagement.vue')
      }
    ]
  }

]



const router = new VueRouter({
  routes
})

// 路由白名单
const offpath = ['/login2', '/404', '/','/login']
router.beforeEach((to, from, next) => {
  //   // AAD登录
    if (to.path.toString().split('=')[0] === '/access_token') {
      const paths = to.path.toString()
      const pathsA = paths.split('=')[1]
      const pathsB = pathsA.split('&')[0]
      sessionStorage.setItem('onetoken',pathsB)
      setTimeout(()=>{
        next('/login')
      },1000)
    }
   else if (offpath.indexOf(to.path) < 0) {
    const userData = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : ''
    const token = userData.accessToken
    const roleList = userData.datas.roles
    if (!token) {
      alert('Please login first')
      next('/login')
    } else {
      switch (to.path) {
        case '/EvaluationManagement':
          if (roleList.indexOf(1) >= 0) {
            next()
          } else {
            next('/404')
          }
          break;
        case '/StaffManagement':
          if (roleList.indexOf(2) >= 0) {
            next()
          } else {
            next('/404')
          }
          break;
        case '/TRPManagement':
          if (roleList.indexOf(2) >= 0) {
            next()
          } else {
            next('/404')
          }
          break;
        case '/PostManagement':
          if (roleList.indexOf(2) >= 0) {
            next()
          } else {
            next('/404')
          }
          break;
        case '/RightsManagement':
          if (roleList.indexOf(3) >= 0) {
            next()
          } else {
            next('/404')
          }
          break;

        default:
          next('/404')
          break;
      }
    }
  } else {
    next()
  }

})

//屏蔽重复路由警告
const VueRouterPush = router.push
router.push = function push(to) {
  undefined
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router